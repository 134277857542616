<template>
  <div>
    <b-modal ref="detailsModal" title="Vehicle Details" size="lg" @hidden="modalHidden">

      <b-form>
        <b-row>

          <b-col md="6">
            <b-form-group
              label="Application / Licence Reference" label-for="apRef"
              description="Application / License Reference">
              <b-form-input id="apRef" v-model="license.apref"
                placeholder="Application Ref"
                type="text" />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Application Type" label-for="apType"
              description="Application / License Type">
              <b-form-input id="apType" v-model="license.aptype"
                placeholder="Application Type"
                type="text" />
            </b-form-group>
          </b-col>

          <b-col cols="6" md="2">
            <b-form-group
              label="Title"
              label-for="aTitle"
              description="Applicants Title">
              <b-form-input id="aTitle" v-model="license.atitle"
                placeholder="Title"/>
            </b-form-group>
          </b-col>

          <b-col cols="6" md="2">
            <b-form-group
              label="Initial"
              label-for="aInits"
              description="Applicants Initials">
              <b-form-input id="aInits" v-model="license.ainits"
                placeholder="Initials"/>
            </b-form-group>
          </b-col>

          <b-col md="8">
            <b-form-group
              label="Applicants Surname"
              label-for="aName"
              description="Applicants Surname">
              <b-form-input id="aName" v-model="license.aname"
                placeholder="Surname"/>
            </b-form-group>
          </b-col>

          <!-- <b-col>
            <b-form-group
              label="Premises Name"
              label-for="bName"
              description="Premises Name">
              <b-form-input id="bName" v-model="license.bname"
                placeholder="Premises"/>
            </b-form-group>
          </b-col> -->

          <b-col>
            <b-form-group
              label="Company Name"
              label-for="apCompany"
              description="Company Name">
              <b-form-input id="apCompany" v-model="license.apcompany"
                placeholder="Company"/>
            </b-form-group>
          </b-col>

        </b-row>

        <b-row>
          <b-col cols="12">
            <h2>Vehicle Details</h2>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Vehicle Registration No." label-for="vRegNo"
              description="Vehicle Registration No. (no spaces)">
              <b-form-input id="vRegNo" v-model="license.lrvehdet.vregno"
                placeholder="Enter Vehicle Registration No."
                type="text" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Vehicle Make" label-for="vMake"
              description="Vehicle Make / Manufacturer">
              <b-form-input id="vMake" v-model="license.lrvehdet.vmake"
                type="text" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Vehicle Model" label-for="vModel"
              description="Vehicle Model">
              <b-form-input id="vModel" v-model="license.lrvehdet.vmodel"
                type="text" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Vehicle Colour" label-for="vCol"
              description="Vehicle Colour">
              <b-form-input id="vModel" v-model="license.lrvehdet.vcol"
                type="text" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Vehicle Seats" label-for="vSeats"
              description="Vehicle No. of Seats">
              <b-form-input id="vSeats" v-model="license.lrvehdet.vseats"
                type="text" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Wheel Chair Accessible" label-for="WheelCh">
              <b-form-checkbox id="WheelCh" v-model="license.lrvehdet.wheelch"
                value="1" unchecked-value="0">
                <i class="fab fa-accessible-icon"></i> Wheel Chair Accessible
              </b-form-checkbox>
            </b-form-group>
          </b-col>


        </b-row>

      </b-form>

    </b-modal>
  </div>
</template>

<script>
  export default {
    props: {
      apKey: {
        type: Number,
        default: -1
      }
    },
    watch: {
      apKey (ctx) {
        // console.log('apkey', this.apKey);
        if (ctx == -1) {
          this.license = {
            lrvehdet: {}
          }
        } else {
          this.apKey = ctx;
          this.$refs.detailsModal.show();
          this.loadData();
        }
      }
    },
    data () {
      return {
        license: {
          lrvehdet: {}
        }
      }
    },
    methods: {
      loadData() {
        this.loading = true;
        if (this.apKey !== -1) {
          this.$http.get('/licensing/jsonLicensingVehicleDetailsByApkey/'+this.apKey)
          .then(response => {
            // console.log(response.data);
            this.license = response.data;
            this.loading = false;
            this.$emit('loadData');
          })
          .catch(error => {
            console.error(error);
            this.license = { lrvehdet: {} };
            this.loading = false;
          });
        } else {
          this.license = { lrvehdet: {} };
          this.loading = false;
        }
      },
      modalHidden () {
        this.$emit('detailsClosed');
      }
    }
  }
</script>
