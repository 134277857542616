<template>
  <div
    id="inspireDownloads"
  >
    <h1>INSPIRE Downloads</h1>
    <small class="text-muted">Infrastructure for Spatial Information in Europe</small>

    <!-- <p>INSPIRE (Infrastructure for Spatial Information in the European Community
       Directive)</p> -->
    <p>
      <a
        href="https://inspire.ec.europa.eu/"
        target="_blank"
      >
        INSPIRE
      </a>
      is a European directive that was brought into UK law in December 2009.
      It requires local authorities to make certain environmental information
      that is held spatially available to view and download.</p>

    <p>
      Metadata regarding datasets is available to view at
      <a
        href="https://data.gov.uk"
        target="_blank"
      >
        https://data.gov.uk
      </a> and searching for
      "Charnwood".
    </p>
    <p
      v-if="files.length == 0"
      class="alert alert-info"
    >
      <i class="fas fa-exclamation-circle"/> No files available!
    </p>

    <div
      v-else
      class="container-fluid">
      <div class="row mb-3 border-bottom">
        <div class="col-md-4">
          <h4>Filename</h4>
        </div>
        <div class="col-md-8">
          <h4>Description</h4>
        </div>
      </div>

      <div
        v-for="(file, index) in files"
        :key="index"
        class="row mb-3"
      >
        <div class="col-md-4">
          <a
            href="#"
            @click="onClickFilename(file.filename)"
          >{{ file.filename }}</a>
        </div>
        <div class="col-md-8">
          {{ file.description }}
        </div>
      </div>
    </div>

    <p
      v-if="files.length == 0"
      class="alert alert-info"
    >
      <i class="fas fa-exclamation-circle"/> No metadata files available!
    </p>

    <div
      v-else
      class="container-fluid">
      <h2>Metadata</h2>
      <div class="row mb-3 border-bottom">
        <div class="col-md-6">
          <h4>Filename</h4>
        </div>
        <div class="col-md-3 text-right">
          <h4>File Size</h4>
        </div>
        <div class="col-md-3 text-right">
          <h4>Created</h4>
        </div>
      </div>

      <div
        v-for="(file, index) in metadata"
        :key="index"
        class="row mb-3"
      >
        <div class="col-md-6">
          <a
            href="#"
            @click="onClickFilename('metadata/' + file.filename)"
          >{{ file.filename }}</a>
        </div>
        <div class="col-md-3 text-right">
          {{ parseInt(file.size / 1024) }} kb
        </div>
        <div class="col-md-3 text-right">
          {{ file.ctime }}
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data () {
    return {
      files: [],
      metadata: []
    }
  },
  mounted () {
    this.readManifest()
    this.readMetadata()
  },
  methods: {
    /**
     * Read the manifest file to get filename and desription array
     */
    readManifest () {
      this.$http.get('/gis/inspireDownloads/manifest')
        .then(response => {
          this.files = response.data.data
        })
    },
    readMetadata () {
      this.$http.get('/gis/inspireDownloads/metadata')
        .then(response => {
          this.metadata = response.data.data
        })
    },
    /**
     * Download a file using axios
     * @param  String filename Filename to download
     */
    onClickFilename (filename) {
      window.open('/downloads/' + filename)

      /**
       * This is too clever for IE11
       */
      // axios.get('/api/gis/inspireDownloads/download', {
      //   responseType: 'blob',
      //   params: {
      //     filename: filename
      //   }
      // })
      //   .then(response => {
      //     const url = window.URL.createObjectURL(new Blob([response.data]));
      //     const link = document.createElement('a');
      //     link.href = url;
      //     link.setAttribute('download', filename);
      //     document.body.appendChild(link);
      //     link.click();
      //   })
      //   .catch()
    }
  }
}
</script>
