<template>

  <div>

    <b-form
      class="mb-3"
      @submit="onSubmit"
      @reset="onReset"
    >

      <b-row class="mb-3">

        <b-col md="6">
          <b-form-group
            label="Search"
            label-for="filter"
            description="Search string / filter for results"
          >
            <b-input-group>
              <b-form-input
                id="filter"
                v-model="form.filter"
                placeholder="Search String"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Search Type"
            label-for="class"
            description="Class of contract to search for"
          >
            <b-form-select
              v-model="form.class"
              :options="form.classOptions"
              required
              @change="classChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="3"
          offset-md="6"
        >
          <b-form-group
            v-if="toggleDates()"
            description="Range based on Contract End Date"
            label="Contract From"
            label-for="dateFrom"
          >
            <b-form-input
              id="dateFrom"
              v-model="form.dateFrom"
              type="date"
              @change="dateChange"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
            v-if="toggleDates()"
            description="Range based on Contract End Date"
            label="Contract To"
            label-for="dateTo"
          >
            <b-form-input
              id="dateTo"
              v-model="form.dateTo"
              :readonly="form.class == 'archived'"
              type="date"
              @change="dateChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          offset-md="8"
          class="text-right"
        >
          <b-btn
            variant="default"
            type="reset"
          >
            <i class="fa fa-times"/> Clear
          </b-btn>
          <b-btn
            variant="primary"
            type="submit"
          >
            <i class="fa fa-search"/> Search
          </b-btn>
        </b-col>
      </b-row>

    </b-form>

    <b-alert
      :show="loading"
      variant="info"
    >
      <i class="fas fa-spinner fa-spin"/> Loading...
    </b-alert>
    <b-alert
      :show="!loading"
      variant="primary"
    >
      <i class="fa fa-info-circle"/> Click on the contract for more detail.
    </b-alert>

    <b-table
      v-if="!loading"
      :fields="fields"
      :items="contracts.data"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      hover
      no-local-sorting
      striped
      @row-clicked="rowClick"
      @sort-changed="sortChanged"
    />

    <b-pagination
      v-if="!loading"
      v-model="page"
      :limit="7"
      :per-page="contracts.per_page"
      :total-rows="contracts.total"
      @change="paginationChange"
    />

    <contractdetails
      ref="contractDetails"
      :contract-id="contractId"
      @detailsClosed="onHidden"
    />

  </div>

</template>

<script>
import contractdetails from './ContractDetails.vue'


export default {
  components: { contractdetails },
  data () {
    return {
      loading: true,
      contractId: -1,
      contracts: [],
      fields: [
        { key: 'ref', sortable: true },
        { key: 'name' },
        { key: 'indicative_value', sortable: true,
          formatter: 'gbp',
          class: 'text-right w-25'
        }
      ],
      sortBy: 'ref',
      sortDesc: true ,
      page: 1,
      moment: window.moment,
      form: {
        filter: null,
        class: 'tender',
        classOptions: [
          { value: 'tender', text:'Contracts for Tender' },
          { value: 'active', text: 'Active Contracts' },
          { value: 'list', text: 'Contract List' },
          { value: 'archived', text: 'Archived Contracts' }
        ],
        dateFrom: null,
        dateTo: null
      },
      dateToMin: null,
      dateToMax: null
    }
  },
  created () {
    this.loadData()
    this.contractDateRange()
  },
  methods: {
    loadData () {
      this.loading = true
      this.$http.get('/finance/jsonContractList', {
        params: {
          fields: this.fields.map(field => field.key),
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          page: this.page,
          filter: this.form.filter,
          class: this.form.class,
          from: this.form.dateFrom,
          to: this.form.dateTo
        }
      })
        .then(response => {
          this.contracts = response.data
          this.loading = false
        })
        .catch(() => {
          this.contracts = []
          this.loading = false
        })
    },
    contractDateRange () { // Get the min and max dateTo from contracts
      this.$http.get('/finance/jsonContractDateRange')
        .then(response => {
          this.dateToMix = this.form.dateFrom = response.data.min
          this.dateToMax = this.form.dateTo = response.data.max
        })
    },
    sortChanged (ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.loadData()
    },
    paginationChange (page) {
      this.page = page
      this.loadData()
    },
    gbp (value) { // Add 1,000's comma separators
      if (value)
        return '£ ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return ''
    },
    toggleDates () { // Show / Hide the date filters based on the selected class
      switch (this.form.class) {
      case 'archived':
      case 'list':
        return true
      default:
        return false
      }
    },
    /* Change the fields based on the class being 'tender' or not */
    classChange (ctx) {
      // Reset the dateTo to the max date as this.form.class will still be equal to pre-change event value
      if (this.form.class == 'archived')
        this.form.dateTo = this.dateToMax
      this.form.class = ctx
      switch(this.form.class) { // Could just use an if instead
      case 'tender':
        this.fields = [
          { key: 'ref', sortable: true },
          { key: 'name' },
          { key: 'indicative_value', sortable: true,
            label: 'Indicative Value',
            formatter: 'gbp',
            class: 'text-right w-25'
          }]
        break
      default:
        // Set dateTo to today's date
        if (this.form.class === 'archived') {
          this.form.dateTo = this.moment().format('YYYY-MM-DD')
        }
        this.fields = [
          { key: 'ref', sortable: true },
          { key: 'name' },
          { key: 'supplier_cost', sortable: true,
            label: 'Cost',
            formatter: 'gbp',
            class: 'text-right w-25'
          }]
        break
      }
      this.page = 1
      this.loadData()
    },
    dateChange () {
      // Only reload the data if the dates are at least a day apart and therefore valid
      if (this.moment(this.form.dateFrom, 'YYYY-MM-DD')
        .diff(this.moment(this.form.dateTo, 'YYYY-MM-DD'), 'days') != 0 )
        this.loadData()
    },
    rowClick (ctx) {
      this.contractId = ctx.id
      this.$refs.contractDetails.$refs.detailsModal.show()
    },
    onHidden () { // Reset the contractId so the ContractDetails watch event triggers
      // this.contractId = -1;
    },
    onReset (e) {
      this.form.filter = null
      this.loadData()
      e.preventDefault()
    },
    onSubmit (e) {
      this.loadData()
      e.preventDefault()
    }
  }
}
</script>
