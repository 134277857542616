<template>
  <div>

    <b-form
      class="mb-3"
      @submit.prevent="onSubmit"
      @reset.prevent="onReset"
    >

      <b-row>
        <b-col cols="12">
          <h2>Private Hire Operators</h2>
        </b-col>

        <b-col
          v-if="false"
          md="4"
        >
          <b-form-group
            label="License Class"
            label-for="apClass"
            description="Vehicle License Class"
          >
            <b-form-select
              id="apClass"
              v-model="form.apClass"
              :options="apClassOptions"
              @change="apClassChange"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
            label="Reference"
            label-for="apRef"
            description="Include the PHOP prefix."
          >
            <b-form-input
              id="apRef"
              v-model="form.apRef"
              placeholder="Operators Reference"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col md="8">
          <b-form-group
            label="License Holder Name"
            label-for="aName"
            description="License Holder Name"
          >
            <b-form-input
              id="aName"
              v-model="form.aName"
              placeholder="Enter License Holder Name"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Company Name"
            label-for="bName"
            description="Company Name">
            <b-form-input
              id="bName"
              v-model="form.bName"
              placeholder="Enter Company Name"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          offset-md="8"
          class="text-right"
        >
          <b-btn
            variant="default"
            type="reset"
          >
            <i class="fa fa-times"/> Clear
          </b-btn>
          <b-btn
            variant="primary"
            type="submit"
          >
            <i class="fa fa-search"/> Search
          </b-btn>
        </b-col>

      </b-row>

    </b-form>

    <drivers-table
      :filter="filter"
      url="/licensing/jsonPrivateHireOperators"
      @showModal="showModal"
    />

    <licensedetails
      :ap-key="apKey"
      @detailsClosed="hideModal"
    />

  </div>
</template>

<script>
import licensedetails from './LicenseDetails.vue'
import driversTable from './partials/DriversTable.vue'

export default {
  components: {
    licensedetails,
    driversTable
  },
  data () {
    return {
      filter: {},
      apKey: -1,
      form: {
        apRef: null,
        aName: null,
        bName: null
      }
    }
  },
  methods: {
    onSubmit () { // Set filter which triggers a watch
      this.filter = {
        apRef: this.form.apRef,
        aName: this.form.aName,
        bName: this.form.bName
      }
    },
    onReset () {
      this.filter = {}
      this.form.apRef = this.form.aName = this.form.bName = null
    },
    hideModal () {
      this.apKey = -1
    },
    showModal (item) {
      this.apKey = parseInt(item.apkey) // Change the apKey to trigger modal
    }
  }
}
</script>
