<template>
  <div>

    <h2>Competent Person Scheme</h2>

    <b-form
      class="mb-3"
      @submit="onSubmit"
      @reset="onReset"
    >
      <b-row>

        <b-col md="8">
          <b-form-group
            label="Address"
            label-for="fullAddress"
            description="Search for Address">
            <b-form-input
              id="fullAddress"
              v-model="form.fullAddress"
              placeholder="Type Any Part of an Address to Search for"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
            label="Postcode"
            label-for="postcode"
            description="Search for Postcode">
            <b-form-input
              id="postCode"
              v-model="form.postcode"
              placeholder="We'll look in a 2km radius"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
            label="CP Scheme"
            label-for="senderCode"
            description="Select a CP Scheme">
            <b-form-select
              id="senderCode"
              v-model="form.senderCode"
              :options="senderCodeOptions"
            />
          </b-form-group>
        </b-col>

        <b-col md="8">
          <b-form-group
            label="Scheme Ref."
            label-for="senderUniqueRecordIdentifier"
            description="Unique Scheme Reference Number.">
            <b-form-input
              id="senderUniqueRecordIdentifier"
              v-model="form.senderUniqueRecordIdentifier"
              placeholder="Enter any Part of the Scheme Reference Number."
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            label="Installer Name"
            label-for="InstallerRegisteredName"
            description="Search for the Installers Name">
            <b-form-input
              id="InstallerRegisteredName"
              v-model="form.installerRegisteredName"
              placeholder="Enter any Part of the Registered Name."
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Start Date"
            label-for="dateStart"
            description="Start Date"
          >
            <b-form-input
              id="dateStart"
              v-model="form.dateStart"
              type="date"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="End Date"
            label-for="dateEnd"
            description="End Date">
            <b-form-input
              id="dateEnd"
              v-model="form.dateEnd"
              type="date"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          offset-md="8"
          class="text-right"
        >
          <b-btn
            variant="default"
            type="reset"
          >
            <i class="fa fa-times"/> Clear
          </b-btn>
          <b-btn
            variant="primary"
            type="submit"
          >
            <i class="fa fa-search"/> Search
          </b-btn>
        </b-col>

      </b-row>
    </b-form>

    <b-alert
      :show="loading"
      variant="info"
    >
      <i class="fas fa-spinner fa-spin"/> Loading...
    </b-alert>

    <b-table
      v-if="!loading"
      :fields="fields"
      :items="records.data"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      hover
      no-local-sorting
      striped
      stacked="md"
      @row-clicked="rowDblClick"
      @sort-changed="sortChanged"
    />

    <b-pagination
      v-if="!loading"
      :total-rows="records.total"
      :per-page="records.per_page"
      :limit="7"
      v-model="page"
      @change="paginationChange"
    />

    <competentdetails
      :record-set-key="recordSetKey"
      @detailsClosed="modalHidden"
    />

  </div>
</template>

<script>
import competentdetails from './CompetentDetails.vue'

export default {
  components: {
    competentdetails
  },
  data () {
    return {
      loading: true,
      page: 1,
      sortBy: 'dateWorkCompleted',
      sortDesc: true,
      recordSetKey: -1,
      records: { data: {} },
      form: {
        fullAddress: null,
        postcode: null,
        senderCode: null,
        senderUniqueRecordIdentifier: null,
        InstallerRegisteredName: null,
        dateStart: '2001-01-01',
        dateEnd: moment().format('YYYY-MM-DD') // eslint-disable-line no-undef
      },
      senderCodeOptions: [
        { value: null, text: '-- Select a Scheme --' }
      ],
      fields: [
        { key: 'fullAddress', label: 'Full Address' },
        { key: 'postCode', sortable: true,
          label: 'Postcode', class: 'text-md-center' },
        { key: 'installerRegisteredName', sortable: true,
          label: 'Installers Name' },
        { key: 'senderCode', sortble: true,
          label: 'CP Scheme', class: 'text-md-center' },
        { key: 'senderUniqueRecordIdentifier', sortable: true,
          label: 'Scheme Ref' },
        { key: 'dateWorkCompleted', sortable: true,
          label: 'Completed', class: 'text-md-center',
          formatter: 'dmy' }

      ]
    }
  },
  created () {
    this.$http.get('/buildctrl/jsonCPSchemes')
      .then(response => {
        for (var key in response.data) {
          this.senderCodeOptions.push(response.data[key])
        }
      })
    this.loadData()
  },
  methods: {
    loadData () {
      this.loading = true
      this.$http.get('/buildctrl/jsonCPSearch', {
        params: {
          full_Address: this.form.fullAddress,
          postcode: this.form.postcode,
          senderCode: this.form.senderCode,
          senderUniqueRecordIdentifier: this.form.senderUniqueRecordIdentifier,
          installerRegisteredName: this.form.installerRegisteredName,
          dateStart: this.form.dateStart,
          dateEnd: this.form.dateEnd,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          page: this.page
        }
      })
        .then(response => {
          this.records = response.data
          this.loading = false
        })
        .catch(() => {
          this.records = { data: {} }
          this.loading = false
        })
    },
    sortChanged (ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.loadData()
    },
    dmy(value) {
      return (value !== null) ? moment(value).format('DD/MM/YYYY') : null // eslint-disable-line no-undef
    },
    onSubmit (e) {
      this.loadData()
      e.preventDefault()
    },
    onReset (e) {
      this.form.fullAddress = this.form.postcode = this.form.senderCode =
        this.form.senderUniqueRecordIdentifier =
        this.form.installerRegisteredName = null
      this.loadData()
      e.preventDefault()
    },
    paginationChange (page) {
      this.page = page
      this.loadData()
    },
    rowDblClick(item) {
      this.recordSetKey = parseInt(item.recordSetKey)
    },
    modalHidden () {
      this.recordSetKey = -1
    }
  }
}
</script>
