<template>

  <div>

    <b-form
      ref="wtf"
      :validated="validated"
      @submit.prevent="submitInterest"
      @reset.prevent="cancelInterest"
    >
      <b-modal
        id="interestModal"
        ref="interestModal"
        size="lg"
        title="Register Interest"
        @hidden="modalHidden"
      >

        <b-row>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Contract Reference"
            >
              <b-form-input
                id="contractRef"
                v-model="form.contract_ref"
                plaintext
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Contract Title"
            >
              <b-form-input
                id="contractName"
                v-model="form.name"
                plaintext
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Contact Email">
              <b-form-input
                id="contactEmail"
                v-model="form.contact_email"
                plaintext
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Company Name"
            >
              <b-form-input
                id="RegisterCompanyName"
                v-model="contact.RegisterCompanyName"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Company Address"
            >
              <b-form-textarea
                id="RegisterCompanyAddress"
                v-model="contact.RegisterCompanyAddress"
                rows="6"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Contact Name"
            >
              <b-form-input
                id="RegisterCompanyContact"
                v-model="contact.RegisterCompanyContact"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Email Address"
            >
              <b-form-input
                id="RegisterContactEmail"
                v-model="contact.RegisterContactEmail"
                type="text"
                required
              />
              <b-form-invalid-feedback>You must enter an email address.</b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Telephone Number"
            >
              <b-form-input
                id="RegisterContactTelephone"
                v-model="contact.RegisterContactTelephone"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Contact Method"
            >
              <b-form-select
                id="RegisterContactMethod"
                v-model="contact.RegisterContactMethod"
                :options="RegisterContactMethodOptions"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              zlabel="Remember Me"
            >
              <b-form-checkbox
                v-b-popover.hover="'This will save a cookie on your machine'"
                id="rememberMe"
                v-model="rememberMe"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group>
          </b-col>

        </b-row>
        <div slot="modal-footer">
          <b-btn
            variant="default"
            type="reset"
          >
            Close
          </b-btn>
          <b-btn
            variant="primary"
            type="submit"
          >
            OK
          </b-btn>
        </div>
      </b-modal>
    </b-form>

  </div>

</template>

<script>
export default {
  props: {
    form: { // Data passed in from ContractDetails.vue
      type: Object,
      default: function() { return {} }
    }
  },
  data() {
    return {
      validated: false,
      // Retrieve the contact cookie if there is one
      rememberMe: this.$cookie.get('rememberme') || false,
      contact: JSON.parse(this.$cookie.get('contact')) ||
        { RegisterContactMethod: 'email' },
      RegisterContactMethodOptions: [
        { value: 'email', text: 'Email' },
        { value: 'post', text: 'Post' }
      ]
    }
  },
  methods: {
    /* Cookies are only saved on validated form submission */
    submitInterest () {
      if (this.rememberMe) {
        // Save a couple of cookies to recall the contact details
        this.$cookie.set('rememberme', true)
        this.$cookie.set('contact', JSON.stringify(this.contact))
      } else {
        // Clear the cookies
        this.$cookie.delete('rememberme')
        this.$cookie.delete('contact')
      }
      // Submit the register interest form
      this.validated = true
      this.$http.post('/finance/registerInterest', {
        contractName: this.form.contractName,
        ref: this.form.ref,
        contractRef: this.form.contractRef,
        id: this.form.id,
        contact: this.contact
      })
        .then(() => {
          this.$refs.interestModal.hide()
          this.$notify({ type: 'success', text: 'You should receive an email confirmation shortly.' })
        })
        .catch(error => {
          this.$notify({ type: 'error', text: error.message })
        })
    },
    cancelInterest () {
      this.$refs.interestModal.hide()
      this.validated = false
    },
    modalHidden() {
      this.$emit('interestClosed')
    }
  }
}
</script>
