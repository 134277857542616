
window._ = require('lodash')

window.Popper = require('popper.js/dist/umd/popper')

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
  window.$ = window.jQuery = require('jquery')

  require('bootstrap')
} catch (e) {
  console.log(e) // eslint-disable-line no-console
}

// Fontawesome
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome' // eslint-disable-line no-unused-vars

library.add(fas, far, fab)

// Kicks off the process of finding <i> tags and replacing with <svg>
dom.watch()

// Fontawesome end

window.moment = require('moment')

