<template>
  <b-navbar
    toggleable="md"
    class="mb-3"
    type="dark"
    variant="dark"
  >
    <b-navbar-toggle target="nav_collapse"/>

    <b-navbar-brand
      :to="'/'"
      href="#"
    >
      Data
    </b-navbar-brand>

    <b-collapse
      id="nav_collapse"
      is-nav
    >

      <b-navbar-nav>
        <b-nav-item-dropdown
          v-for="menu in menus"
          :key="menu.title"
          :text="menu.title"
        >
          <b-dropdown-item
            v-for="item in menu.items"
            :key="item.text"
            :class="item.class"
            :to="item.link"
          >
            {{ item.text }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  data () {
    return {
      menus: [
        { title: 'Building Control', items: [
          { link: '/buildctrl/competentsearch', text: 'Competent Person Scheme' }
        ]},
        { title: 'Licensing', items: [
          { link: '#LicensingAct', text: 'Licensing Act', class: 'font-weight-bold' },
          { link: '/licensing/actsearch/CLUB', text: 'Club Premises' },
          { link: '/licensing/actsearch/PERS', text: 'Personal' },
          { link: '/licensing/actsearch/PREM', text: 'Premises' },
          { link: '/licensing/actsearch/TEN', text: 'Temporary Event Notice' },
          { link: '#Taxis', text: 'Taxis', class: 'font-weight-bold' },
          { link: '/licensing/driversearch', text: 'Drivers' },
          { link: '/licensing/privatehireoperators', text: 'Private Hire Operators' },
          { link: '/licensing/vehiclesearch/PH', text: 'Private Hire Vehicles' },
          { link: '/licensing/vehiclesearch/HC', text: 'Hackney Carriages' }
          // { link: '/licensing/vehiclesearch/DESIGNATED', text: 'Designated Vehicle List' }
        ]},
        { title: 'Finance', items: [
          { link: '/finance/contractlist', text: 'Contract Search' },
          { link: '/finance/csv', text: 'CSV Contract List' }
        ]},
        { title: 'GIS', items: [
          { link: '/gis/inspireDownloads', text: 'Inspire Downloads' }
        ]}        ]
    }
  }
}
</script>
