<template>
  <div id="app">
    <navbar></navbar>
    <router-view></router-view>
  </div>
</template>

<script>
  import navbar from './layouts/NavBar.vue'

  export default {
    name: 'app',
    components: {
      navbar
    }
  }
</script>
