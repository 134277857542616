<template>
  <div id="driversTable">
    <b-alert
      :show="loading"
      variant="info"
    >
      <i class="fas fa-spinner fa-spin"/> Loading...
    </b-alert>

    <b-table
      v-if="!loading"
      :items="license.data"
      :fields="fields"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      no-local-sorting
      striped
      hover
      stacked="md"
      @row-clicked="rowClick"
      @sort-changed="sortChanged"
    >
      <template
        slot="aName"
        slot-scope="data"
      >
        {{ (data.item.atitle) ? data.item.atitle : '' }}{{ (data.item.ainits) ? data.item.ainits + ' ' : '' }}{{ (data.item.aname) ? data.item.aname.trim() : '' }}
      </template>
    </b-table>

    <b-pagination
      v-if="!loading"
      v-model="page"
      :total-rows="license.total"
      :per-page="license.per_page"
      :limit="7"
      @change="paginationChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    filter: {
      type: Object,
      default: () => {
        return {
          apRef: null,
          aName: null,
          bName: null
        }
      }
    },
    url: {
      type: String,
      default: '/licensing/jsonPrivateHireOperators'
    }
  },
  data () {
    return {
      loading: true,
      page: 1,
      sortBy: 'apref',
      sortDesc: false,
      apKey: -1,
      fields: [
        { key: 'apref', sortable: true, label: 'Ref' },
        { key: 'apdate', sortable: true, label: 'Date',
          class: 'text-md-center',
          formatter: 'dmy' },
        { key: 'aName', label: 'Holder' },
        { key: 'bname', label: 'Premises',
          sortable: true
        },
        { key: 'description' },
        { key: 'licStatus', label: 'Status',
          class: 'text-md-center' },
        { key: 'licstart', label: 'Start',
          class: 'text-md-center',
          formatter: 'dmy' },
        // TODO: Why doesn't expiryDate sort?
        { key: 'expiryDate', sortable: false, label: 'Expires',
          class: 'text-md-center',
          formatter: 'dmy' }
      ]
    }
  },
  // Use a watch on filkter to handle search sumbission/reset
  watch: {
    filter: function () {
      this.loadData()
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    dmy(value) {
      return (value) ? moment(value).format('DD/MM/YYYY') : null // eslint-disable-line no-undef
    },
    loadData() {
      this.loading = true
      var fields = this.fields.map(field => field.key)
      fields.push('apKey', 'aTitle', 'aInits')
      this.$http.get(this.url, {
        params: {
          fields: fields,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          page: this.page,
          apRef: this.filter.apRef,
          aName: this.filter.aName,
          bName: this.filter.bName
        }
      })
        .then(response => {
          this.license = response.data
          this.loading = false
        })
        .catch(() => {
          this.license = {}
          this.loading = false
        })
    },
    paginationChange (page) {
      this.page = page
      this.loadData()
    },
    rowClick(item, index, evt) {
      // this.apKey = parseInt(item.apkey)
      // console.log(item, index, evt)
      this.$emit('showModal', item)
    },
    sortChanged (ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.loadData()
    },
  }
}
</script>
