<template>

<div>

  <h2>Licensing Act Search</h2>


  <b-form @submit="onSubmit" @reset="onReset" class="mb-3">
  <b-row>

    <b-col md="4">
      <b-form-group
        label="Application Type" label-for="apType"
        description="Application Type eg. Club, Permises, Personal">
        <b-form-select id="apType" v-model="form.apType" @change="apTypeChange"
          :options="apTypeOptions" />
      </b-form-group>
    </b-col>

    <b-col md="4">
      <b-form-group
        label="Application Reference" label-for="apRef"
        description="Licensing Application Reference"
        placeholder="Enter the Application Reference">
        <b-form-input id="apRef" v-model="form.apRef"
          type="text" />
      </b-form-group>
    </b-col>

    <b-col md="4">
      <b-form-group
        label="License Holder Name" label-for="aName"
        description="Licensing Holder Name"
        placeholder="Enter any part of License Holder Name">
        <b-form-input id="aName" v-model="form.aName"
          type="text" />
      </b-form-group>
    </b-col>

    <b-col>
      <b-form-group
        label="Premises Name" label-for="bName"
        description="Premises Name"
        placeholder="Enter any part of Premises Name">
        <b-form-input id="bName" v-model="form.bName"
          type="text" />
      </b-form-group>
    </b-col>

    <b-col md="4" offset-md="8" class="text-right">
      <b-btn variant="default" type="reset"><i class="fa fa-times"></i> Clear</b-btn>
      <b-btn variant="primary" type="submit"><i class="fa fa-search"></i> Search</b-btn>
    </b-col>
  </b-row>
  </b-form>

  <b-alert variant="info" :show="loading"><i class="fas fa-spinner fa-spin"></i> Loading...</b-alert>

  <b-table striped hover stacked="md" :items="license.data" :fields="fields"
    v-if="!loading" @row-clicked="rowDblClick"
    :sort-by="sortBy" :sort-desc="sortDesc" no-local-sorting @sort-changed="sortChanged">
    <template slot="aName" slot-scope="data">{{ (data.item.aTitle) ? data.item.aTitle.trim()+' ' : '' }}{{ (data.item.aInits) ? data.item.aInits + ' ' : '' }}{{ data.item.aName.trim() }}</template>
    <template slot="expiryDate" slot-scope="data">{{ dmy(data.item.expiryDate)  }}</template>
    <template slot="licStart" slot-scope="data">{{ dmy(data.item.licStart) }}</template>
  </b-table>


  <b-pagination :total-rows="license.total" :per-page="license.per_page"
    :limit="7" v-model="page" @change="paginationChange" v-if="!loading">
  </b-pagination>

  <licensedetails v-bind:apKey="this.apKey" v-on:detailsClosed="hideModal" />

</div>

</template>

<script>
  import licensedetails from './LicenseDetails.vue';

  export default {
    props: {
      apType: {
        type: String,
        default: 'CLUB'
      }
    },
    watch: {
      // If the apType "prop" changes loadData
      apType (ctx) {
        this.page = 1;
        this.form.apType = ctx;
        this.apTypeChange(ctx);
      }
    },
    components: {
      licensedetails
    },
    data: function () {
      return {
        fields: [
          { key: 'apRef', sortable: true, label: 'Ref' },
          { key: 'apDate', sortable: true, label: 'Date',
          class: 'text-md-center',
            formatter: 'dmy' },
          { key: 'aName', label: 'Holder' },
          { key: 'bName', label: 'Premises' },
          { key: 'description' },
          { key: 'licStatus', label: 'Status',
            class: 'text-md-center' },
          { key: 'licStart', label: 'Start',
          class: 'text-md-center',
            formatter: 'dmy' }
          // { key: 'expiry_Date', sortable: true, label: 'Expires',
          // class: 'text-md-center',
          //   formatter: 'dmy' }
        ],
        loading: true,
        apKey: -1,
        form: {
          apType: this.apType,
          apRef: null,
          aName: null,
          bName: null
        },
        apTypeOptions: [
          { value: 'CLUB' , text: 'Club Premises' },
          { value: 'PREM' , text: 'Premises' },
          { value: 'TEN' , text: 'Temporary Event Notice' },
          { value: 'PERS' , text: 'Personal' }
        ],
        license: function() {
          return { };
        },
        sortBy: 'apRef',
        sortDesc: false,
        page: 1
      }
    },
    created () {
      this.loadData();
    },
    methods: {
      loadData() {
        this.loading = true;
        var fields = this.fields.map(field => field.key);
        fields.push('apKey', 'aTitle', 'aInits');
        this.$http.get('/licensing/jsonActSearch', {
          params: {
            fields: fields,
            sortBy: this.sortBy,
            sortDesc: this.sortDesc,
            page: this.page,
            apType: this.form.apType,
            apRef: this.form.apRef,
            aName: this.form.aName,
            bName: this.form.bName
          }
        })
        .then(response => {
          this.license = response.data;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.license = {};
          this.loading = false;
        })
      },
      sortChanged (ctx) {
        this.sortBy = ctx.sortBy;
        this.sortDesc = ctx.sortDesc;
        this.loadData();
      },
      dmy(value) {
        return (value) ? moment(value).format('DD/MM/YYYY') : null;
      },
      apTypeChange (ctx) {
        this.form.apType = ctx;

        // if (this.form.apType === 'PERS') {
          // this.fields = [
          //   { key: 'apRef', sortable: true, label: 'Ref' },
          //   { key: 'apDate', sortable: true, label: 'Date',
          //   class: 'text-md-center',
          //     formatter: 'dmy' },
          //   { key: 'aName', label: 'Holder' },
          //   { key: 'bName', label: 'Premises' },
          //   { key: 'description' },
          //   { key: 'licStatus', label: 'Status',
          //     class: 'text-md-center' },
          //   { key: 'licStart', label: 'Start',
          //     class: 'text-md-center',
          //     formatter: 'dmy' }
          // ];
        // } else {
        //   this.fields = [
        //     { key: 'apRef', sortable: true, label: 'Ref' },
        //     { key: 'apDate', sortable: true, label: 'Date',
        //     class: 'text-md-center',
        //       formatter: 'dmy' },
        //     { key: 'aName', label: 'Holder' },
        //     { key: 'bName', label: 'Premises' },
        //     { key: 'description' },
        //     { key: 'licStatus', label: 'Status',
        //       class: 'text-md-center' },
        //     { key: 'licStart', label: 'Start',
        //     class: 'text-md-center',
        //       formatter: 'dmy' },
        //     { key: 'expiry_Date', sortable: true, label: 'Expires',
        //     class: 'text-md-center',
        //       formatter: 'dmy' }
        //   ]
        // }
        this.page = 1; // Reset the page so not to be off pagination
        this.loadData();
      },
      onSubmit (e) {
        this.loadData();
        e.preventDefault();
      },
      onReset (e) {
        this.form.apRef = this.form.aName = this.form.bName = null;
        this.loadData();
        e.preventDefault();
      },
      paginationChange (page) {
        this.page = page;
        this.loadData();
      },
      rowDblClick(item, index, evt) {
        this.apKey = parseInt(item.apKey);
      },
      hideModal () { // Reset the apKey so a change is registered if the same row is clicked
        this.apKey = -1;
      }
    }
  }
</script>
