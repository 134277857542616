<template>
  <div class="container">
    <h1>Charnwood Data</h1>
    <p>Welcome to the Data project. This service is designed to provide access to public data sets from within the Charnwood databases.</p>
    <h2>Open API</h2>
    <p>
      The API for this project is open too. You can query data using it to return the information as JSON.
    </p>
    <p>
      API Documentation can be found here:
      <a
        href="https://documenter.getpostman.com/view/5124910/RWTrMvzd"
        target="_blank">API Documentation</a>
    </p>
  </div>
</template>

<script>
export default {

}
</script>
