<template>
  <div>

    <b-modal
      ref="detailsModal"
      title="License Details"
      size="lg"
      @hidden="modalHidden"
    >

      <b-alert
        :show="loading"
        variant="info"
      >
        <i class="fas fa-spinner fa-spin"/> Loading...
      </b-alert>

      <b-form v-if="!loading">
        <b-row>

          <b-col md="6">
            <b-form-group
              label="Application Refrence"
              label-for="apRef"
              description="Application / License Reference">
              <b-form-input
                id="apRef"
                v-model="license.apref"
                placeholder="Application Ref"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Application Type"
              label-for="apType"
              description="Application / License Type">
              <b-form-input
                id="apType"
                v-model="license.aptype"
                placeholder="Application Type"/>
            </b-form-group>
          </b-col>

          <b-col
            cols="6"
            md="2"
          >
            <b-form-group
              label="Title"
              label-for="aTitle"
              description="Applicants Title">
              <b-form-input
                id="aTitle"
                v-model="license.atitle"
                placeholder="Title"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="6"
            md="2">
            <b-form-group
              label="Initial"
              label-for="aInits"
              description="Applicants Initials">
              <b-form-input
                id="aInits"
                v-model="license.ainits"
                placeholder="Initials"/>
            </b-form-group>
          </b-col>

          <b-col md="8">
            <b-form-group
              label="Applicants Surname"
              label-for="aName"
              description="Applicants Surname">
              <b-form-input
                id="aName"
                v-model="license.aname"
                placeholder="Surname"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
              label="Premises Name"
              label-for="bName"
              description="Premises Name">
              <b-form-input
                id="bName"
                v-model="license.bname"
                placeholder="Premises"
              />
            </b-form-group>
          </b-col>

        </b-row>

        <b-row v-if="license.lrliqprem">
          <b-col md="12">
            <h2>Opening Hours</h2>
          </b-col>

          <b-col
            cols="6"
            md="4"
          >
            <b-form-group
              label="Monday"
              label-for="openMon"
              description="Monday Hours">
              <b-form-input
                id="openMon"
                v-model="license.lrliqprem.openmon"
                placeholder="Monday"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="6"
            md="4"
          >
            <b-form-group
              label="Tuesday"
              label-for="openTue"
              description="Tuesday Hours">
              <b-form-input
                id="openTue"
                v-model="license.lrliqprem.opentue"
                placeholder="Tuesday"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="6"
            md="4"
          >
            <b-form-group
              label="Wednesday"
              label-for="openWed"
              description="Wednesday Hours">
              <b-form-input
                id="openWed"
                v-model="license.lrliqprem.openwed"
                placeholder="Wednesday"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="6"
            md="4"
          >
            <b-form-group
              label="Thursday"
              label-for="openThu"
              description="Thursday Hours">
              <b-form-input
                id="openThu"
                v-model="license.lrliqprem.openthu"
                placeholder="Thursday"/>
            </b-form-group>
          </b-col>

          <b-col
            cols="6"
            md="4"
          >
            <b-form-group
              label="Friday"
              label-for="openFri"
              description="Friday Hours">
              <b-form-input
                id="openFri"
                v-model="license.lrliqprem.openfri"
                placeholder="Friday"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="6"
            md="4"
          >
            <b-form-group
              label="Saturday"
              label-for="openSat"
              description="Saturday Hours">
              <b-form-input
                id="openSat"
                v-model="license.lrliqprem.opensat"
                placeholder="Saturday"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="6"
            md="4"
          >
            <b-form-group
              label="Sunday"
              label-for="openSun"
              description="Sunday Hours">
              <b-form-input
                id="openSun"
                v-model="license.lrliqprem.opensun"
                placeholder="Sunday"
              />
            </b-form-group>
          </b-col>

        </b-row>

        <b-row v-if="license.lrliqpremx && license.lrliqpremx.length > 0">
          <b-col md="12"><h2>Activities</h2></b-col>
        </b-row>

        <b-row
          v-for="activity in license.lrliqpremx"
          :key="activity.code"
          class="mb-2"
        >
          <b-col md="5">
            <strong>{{ activity.lrenttypes.description }}</strong>
          </b-col>
          <b-col md="7">
            <p v-if="activity.seasonal">{{ activity.seasonal }}</p>
            <p v-if="activity.nonstandard">{{ activity.nonstandard }}</p>
            <p v-if="activity.otherdet">{{ activity.otherdet }}</p>
          </b-col>
        </b-row>

      </b-form>

    </b-modal>

  </div>
</template>

<script>
export default {
  props: {
    apKey: { // The apkey of the row to load
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      loading: true,
      license: { // Basic empty structure of license
        lrliqprem: {},
        lrliqpremx: {
          lrenttypes: {}
        }
      }
    }
  },
  watch: {
    apKey (ctx) { // Show the modal if the apKey changes
      if (ctx == -1) {
        this.license = {
          lrliqprem: {}
        }
      } else {
        this.$refs.detailsModal.show()
        this.loadData()
      }
    }
  },
  created () {
    this.apKey = -1
  },
  methods: {
    // Use axios to retrieve the license details
    loadData () {
      this.loading = true
      this.$http.get('/licensing/jsonLicensingDetailsByApkey/'+this.apKey)
        .then(response => {
          this.license = response.data
          this.loading = false
          this.$emit('loadData')
        })
        .catch(() => {
          this.license = {}
          this.loading = false
        })
    },
    modalHidden () {
      this.$emit('detailsClosed')
    }
  }
}
</script>
