import 'core-js'

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vue from 'vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

import BootstrapVue from 'bootstrap-vue'
import VueRouter from 'vue-router'
import VueCookie from 'vue-cookie'
// import * as Cookies from 'tiny-cookie'

import App from './components/App.vue'
import Home from './components/Home.vue'

/* Licensing */
import actsearch from './components/licensing/ActSearch.vue'
import vehiclesearch from './components/licensing/VehicleSearch.vue'
import driversearch from './components/licensing/DriverSearch.vue'
import privatehireoperators from './components/licensing/PrivateHireOperators.vue'


/* Finance */
import contractlist from './components/finance/ContractList.vue'

/* Building Control */
import competentsearch from './components/buildctrl/CompetentSearch.vue'

// import pagetest from './components/pagetest.vue'

import inspireDownloads from './components/gis/inspireDownloads.vue'

import Notifications from 'vue-notification'

require('./bootstrap')

window.Vue = Vue
// window.Vue = require('vue')
Vue.use(BootstrapVue)
Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(Notifications)
Vue.use(VueCookie)

const routes = [
  { path: '/', component: Home },
  { path: '/licensing/actsearch/:apType', component: actsearch, props: true },
  { path: '/licensing/vehiclesearch/:apClass', component: vehiclesearch, props: true },
  { path: '/licensing/driversearch', component: driversearch },
  { path: '/licensing/privatehireoperators', component: privatehireoperators },
  { path: '/finance/contractlist', component: contractlist },
  { path: '/finance/csv',
    beforeEnter () {
      // Uses beforeEnter() to pass a route outside of this router
      window.location.href = '/api/finance/csv'
    }},
  { path: '/buildctrl/competentsearch', component: competentsearch },
  { path: '/buildctrl/cpsearch', redirect: '/buildctrl/competentsearch' },
  { path: '/gis/inspireDownloads', component: inspireDownloads }

]

const router = new VueRouter({
  routes,
  mode: 'history'
})

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

axios.defaults.baseURL = '/api' // Prefix ALL axios requests with /api
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
let token = document.head.querySelector('meta[name="csrf-token"]')

if (token) {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
} else {
  console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token') // eslint-disable-line no-console
}

new Vue({
  el: '#app',
  components: {
    App
  },
  template: '<App/>',
  router
}).$mount('#app')
