<template>
  <div>

    <h2 v-if="apClass == 'DESIGNATED'">
      <i class="fab fa-accessible-icon" /> Designated Vehicle List
    </h2>

    <b-form
      v-else
      class="mb-3"
      @submit="onSubmit"
      @reset="onReset"
    >

      <b-row>
        <b-col cols="12">
          <h2>Vehicle Search</h2>
        </b-col>

        <b-col md="3">
          <b-form-group
            label="License Class"
            label-for="apClass"
            description="Vehicle License Class">
            <b-form-select
              id="apClass"
              v-model="form.apClass"
              :options="apClassOptions"
              @change="apClassChange"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
            label="Application Reference"
            label-for="apRef"
            description="Application / License Reference">
            <b-form-input
              id="apRef"
              v-model="form.apRef"
              placeholder="Enter Application / License Reference"
              type="text" />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="License Holder Name"
            label-for="aName"
            description="License Holder Name">
            <b-form-input
              id="aName"
              v-model="form.aName"
              placeholder="Enter License Holder Name"
              type="text" />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Company Name"
            label-for="apCompany"
            description="Company Name">
            <b-form-input
              id="apCompany"
              v-model="form.apCompany"
              placeholder="Enter Company Name"
              type="text" />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
            label="Vehicle Registration No."
            label-for="vRegNo"
            description="Vehicle Registration No. (no spaces)">
            <b-form-input
              id="vRegNo"
              v-model="form.vRegNo"
              placeholder="Enter Vehicle Registration No."
              type="text" />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
            label="Vehicle Make"
            label-for="vMake"
            description="Vehicle Make / Manufacturer">
            <b-form-select
              id="vMake"
              v-model="form.vMake"
              :options="vMakeOptions"
              @change="getVModelsByMake"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
            label="Vehicle Model"
            label-for="vModel"
            description="Vehicle Model">
            <b-form-select
              id="vModel"
              v-model="form.vModel"
              :options="vModelOptions" />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
            label="Vehicle Colour"
            label-for="vCol"
            description="Vehicle Colour">
            <b-form-select
              id="vModel"
              v-model="form.vCol"
              :options="vColOptions" />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
            label="Vehicle Seats"
            label-for="vSeats"
            description="Vehicle No. of Seats">
            <b-form-select
              id="vSeats"
              v-model="form.vSeats"
              :options="vSeatsOptions" />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
            label="Wheel Chair Accessible"
            label-for="WheelCh">
            <b-form-checkbox
              id="WheelCh"
              v-model="form.WheelCh"
              :unchecked-value="null"
              value="1"
            >
              <i class="fab fa-accessible-icon" /> Wheel Chair Accessible
            </b-form-checkbox>
          </b-form-group>
        </b-col>

      </b-row>

      <b-row>
        <b-col class="text-right">
          <b-btn
            variant="default"
            type="reset"
          >
            <i class="fa fa-times" /> Clear
          </b-btn>
          <b-btn
            variant="primary"
            type="submit"
          >
            <i class="fa fa-search"/> Search
          </b-btn>
        </b-col>
      </b-row>

    </b-form>
    <b-alert
      :show="loading"
      variant="info"
    >
      <i class="fas fa-spinner fa-spin" /> Loading...
    </b-alert>

    <b-table
      v-if="!loading"
      :items="license.data"
      :fields="fields"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      no-local-sorting
      striped
      hover
      stacked="md"
      @row-clicked="rowDblClick"
      @sort-changed="sortChanged"
    >
      <template
        slot="cell(apref)"
        slot-scope="data"
      >
        {{ data.item.apref }}
        <i
          v-if="parseInt(data.item.lrvehdet.wheelch)"
          class="fab fa-accessible-icon"/>
      </template>
      <template
        slot="cell(aname)"
        slot-scope="data"
      >
        {{ (data.item.atitle) ? (data.item.atitle+'').trim() + ' ' : '' }}
        {{ (data.item.ainits) ? data.item.ainits + ' ' : '' }}
        {{ (data.item.aname + '').trim() }}
      </template>
    </b-table>

    <b-pagination
      v-if="!loading"
      :total-rows="license.total"
      :per-page="license.per_page"
      :limit="7"
      v-model="page"
      @change="paginationChange"
    />

    <vehicledetails
      :ap-key="apKey"
      @detailsClosed="hideModal"
    />
  </div>
</template>

<script>
import vehicledetails from './VehicleDetails.vue'

export default {
  components: {
    vehicledetails
  },
  props: {
    apClass: {
      type: String,
      default: 'PH'
    }
  },
  data () {
    return {
      loading: true,
      apKey: -1,
      form: {
        apClass: this.apClass,
        apRef: null,
        aName: null,
        // bName: null,
        apCompany: null,
        vRegNo: null,
        vMake: null,
        vModel: null,
        vCol: null,
        vSeats: null,
        WheelCh: null
      },
      license: () => {},
      fields: [
        { key: 'apref', sortable: true, label: 'Ref' },
        { key: 'apdate', sortable: true, label: 'Date',
          class: 'text-md-center',
          formatter: 'dmy' },
        { key: 'aname', label: 'Holder' },
        { key: 'apcompany', label: 'Company', sortable: true },
        // { key: 'bname', label: 'Premises' },
        { key: 'description' },
        { key: 'licStatus', label: 'Status',
          class: 'text-md-center' },
        { key: 'licstart', label: 'Start',
          class: 'text-md-center',
          formatter: 'dmy' },
        { key: 'expiryDate', sortable: true, label: 'Expires',
          class: 'text-md-center',
          formatter: 'dmy' }
      ],
      sortBy: 'apref',
      sortDesc: false,
      page: 1,
      apClassOptions: [
        { value: 'PH', text: 'Private Hire Vehicle' },
        { value: 'HC', text: 'Hackney Carriage' }
      ],
      vMakeOptions: [
        { value: null, text: '-- Select an Option --' }
      ],
      vModelOptions: [
        { value: null, text: '-- Select a Make  --' }
      ],
      vColOptions: [
        { value: null, text: '-- Select an Option ---' }
      ],
      vSeatsOptions: [
        { value: null, text: '-- Select an Option ---' }
      ]
    }
  },
  watch: {
    apClass (ctx) {
      this.form.apClass = ctx
      this.page = 1
      this.loadData()
    }
  },
  created () {
    this.getVMakes()
    this.getVColours()
    this.getVSeats()
    this.loadData()
  },
  methods: {
    getVMakes: function () {
      this.$http.get('/licensing/jsonGetVMakes')
        .then(response => {
          for (var key in response.data) {
            this.vMakeOptions.push(response.data[key])
          }
        })
        .catch(error => {
          console.error(error) // eslint-disable-line
        })
    },
    getVModelsByMake (vMake) {
      if (vMake) {
        this.$http.get('/licensing/jsonGetVModelsByMake/'+vMake)
          .then(response => {
            this.vModelOptions = [{
              value: null, text: '-- Select an Option --'
            }]
            for (var key in response.data) {
              this.vModelOptions.push(response.data[key])
            }
            this.form.vModel = null
          })
          .catch(error => {
            console.error(error) // eslint-disable-line
          })
      } else {
        this.vModelOptions = [{
          value: null, text: '-- Select a Make --'
        }]
        this.form.vModel = null
      }
    },
    getVColours() {
      this.$http.get('/licensing/jsonGetVColours/')
        .then(response => {
          for (var key in response.data) {
            this.vColOptions.push(response.data[key])
          }
        })
        .catch(error => {
          console.error(error) // eslint-disable-line
        })
    },
    getVSeats() {
      this.$http.get('/licensing/jsonGetVSeats/')
        .then(response => {
          for (var key in response.data) {
            this.vSeatsOptions.push(response.data[key])
          }
        })
        .catch(error => {
          console.error(error) // eslint-disable-line
        })
    },
    onSubmit (e) {
      this.page = 1
      this.loadData()
      e.preventDefault()
    },
    onReset (e) {
      this.form.apRef =
      this.form.aName =
      this.form.bName =
      this.form.vRegNo =
      this.form.vMake =
      this.form.vModel =
      this.form.vCol =
      this.form.vSeats =
      this.form.WheelCh = null
      this.page = 1
      this.loadData()
      e.preventDefault()
    },
    apClassChange (ctx) {
      this.form.apClass = ctx
      this.page = 1
      this.loadData()
    },
    loadData() {
      // this.$refs.detailsModal.show()
      this.loading = true
      var fields = this.fields.map(field => field.key)
      fields.push('apKey', 'aTitle', 'aInits')
      this.$http.get('/licensing/jsonVehicleSearch', {
        params: {
          fields: fields,
          apClass: this.form.apClass,
          apRef: this.form.apRef,
          aName: this.form.aName,
          apCompany: this.form.apCompany,
          // bName: this.form.bName,
          vRegNo: this.form.vRegNo,
          vMake: this.form.vMake,
          vModel: this.form.vModel,
          vCol: this.form.vCol,
          vSeats: this.form.vSeats,
          WheelCh: this.form.WheelCh,
          page: this.page,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc
        }
      })
        .then(response => {
          // console.log(response.data)
          this.license = response.data
          this.loading = false
        })
        .catch(error => {
          console.error(error) // eslint-disable-line
          this.loading = false
        })
    },
    rowDblClick(item) {
      // console.log(item)
      this.apKey = parseInt(item.apkey)
    },
    dmy(value) {
      return (value) ? moment(value).format('DD/MM/YYYY') : null
    },
    sortChanged (ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.loadData()
    },
    paginationChange (page) {
      this.page = page
      this.loadData()
    },
    hideModal () {
      this.apKey = -1
    }
  }
}
</script>
