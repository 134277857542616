<template>
  <div>
    <b-modal ref="detailsModal" title="CP Scheme Work Details" size="lg" @hidden="modalHidden">

      <b-alert variant="info" :show="loading"><i class="fas fa-spinner fa-spin"></i> Loading...</b-alert>

      <b-form v-if="!loading">
        <b-row>

          <b-col cols="12">
            <b-form-group
              label="Address of Works" label-for="fullAddress"
              description="Full Address of Works">
              <b-form-input id="fullAddress" v-model="record.fullAddress"
                placeholder="Full Address"
                type="text" readonly/>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="CP Scheme" label-for="senderCode"
              description="CP Scheme">
              <b-form-input id="senderCode" v-model="record.senderCode"
                placeholder="CP Scheme"
                type="text" readonly/>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Submission Date" label-for="submissionDate"
              description="Submission Date">
              <b-form-input id="submissionDate" v-model="record.submissionDateIso"
                :formatter="isoDate"
                type="date" readonly/>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Status" label-for="statusType"
              description="Status">
              <b-form-input id="submissionDate" v-model="record.statusType"
                placeholder="Status"
                type="text" readonly/>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Property Type" label-for="typeOfProperty"
              description="Property Type">
              <b-form-input id="typeOfProperty" v-model="record.typeOfProperty"
                placeholder="Property Type"
                type="text" readonly/>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Reason" label-for="statusReason"
              description="Reason">
              <b-form-input id="statusReason" v-model="record.statusReason"
                placeholder="Reason"
                type="text" readonly/>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="CP Scheme Ref." label-for="senderUniqueRecordIdentifier"
              description="Unique CP Scheme Reference">
              <b-form-input id="senderUniqueRecordIdentifier" v-model="record.senderUniqueRecordIdentifier"
                placeholder="CP Scheme Ref."
                type="text" readonly/>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Certificate Ref." label-for="cPSchemeCertificateReference"
              description="Certificate Reference">
              <b-form-input id="cPSchemeCertificateReference" v-model="record.cPSchemeCertificateReference"
                placeholder="Certificate Ref."
                type="text" readonly/>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Commissioning Required" label-for="commissioningRequired"
              description="Commissioning Required">
              <b-form-input id="commissioningRequired" v-model="record.commissioningRequired"
                placeholder="Commissioning Required"
                type="text" readonly/>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Date Work Commenced" label-for="dateWorkCommenced"
              description="Date Work Commenced">
              <b-form-input id="dateWorkCommenced" v-model="record.dateWorkCommencedIso"
                type="date" readonly/>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Date Work Completed" label-for="dateWorkCompleted"
              description="Date Work Completed">
              <b-form-input id="dateWorkCompleted" v-model="record.dateWorkCompletedIso"
                type="date" readonly/>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Description of Works" label-for="workDescription"
              description="Description of Works">
              <b-form-textarea id="workDescription" v-model="record.descriptionOfWorkItem"
                rows="6" readonly/>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="BC Application No." label-for="bCNo"
              description="BC Application No.">
              <b-form-input id="bCNo" v-model="record.bCNo"
                placeholder="BC Application No."
                type="text" readonly/>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Green Deal Finance" label-for="greenDealFinance"
              description="Green Deal Finance">
              <b-form-input id="greenDealFinance" v-model="record.greenDealFinance"
                placeholder="Green Deal Finance"
                type="text" readonly/>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Comments" label-for="comments"
              description="Comments">
              <b-form-textarea id="comments" v-model="record.comments"
                rows="6" readonly/>
            </b-form-group>
          </b-col>

        </b-row>
      </b-form>

    </b-modal>

  </div>
</template>

<script>
  export default {
    props: {
      recordSetKey: {
        type: Number,
        default: -1
      }
    },
    watch: {
      recordSetKey (ctx) {
        this.recordSetKey = ctx;
        if (ctx != -1) {
          this.$refs.detailsModal.show();
          this.loadData();
        }
      }
    },
    data () {
      return {
        loading: true,
        record: {}
      }
    },
    methods: {
      loadData () {
        this.loading = true;
        this.$refs.detailsModal.show();
        this.$http.get('/buildctrl/jsonCPDetailsByRecordSetKey/'+this.recordSetKey)
          .then(response => {
            this.record = response.data;
            // Set some iso date formats YYYY-MM-DD
            this.record.submissionDateIso = this.isoDate(this.record.submissionDate);
            this.record.dateWorkCommencedIso = this.isoDate(this.record.dateWorkCommenced);
            this.record.dateWorkCompletedIso = this.isoDate(this.record.dateWorkCompleted);
            this.$http.get('/buildctrl/jsonDescriptionOfWorkItemByRecordSetKey/'+this.recordSetKey)
              .then(response => {
                this.record.descriptionOfWorkItem = response.data;
                this.loading = false;
              })
              .catch(error => {
                console.error(error);
              })

          })
          .catch(error => {
            console.error(error);
            this.record = {};
            this.loading = false;
          });
      },
      isoDate (value) {
        if (!value) return value; // Handle nulls
        return moment(value).format('YYYY-MM-DD');
      },
      descriptionOfWorkItem (value) {
        var descriptionOfWorkItem = [];
        if (value)
          descriptionOfWorkItem = value.map(item => value.key);
        return descriptionOfWorkItem.join('\n');
      },
      modalHidden () {
        this.$emit('detailsClosed');
      }
    }
  }
</script>
