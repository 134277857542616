<template>
  <div>
    <b-modal
      ref="detailsModal"
      size="lg"
      title="Contract Details"
    >
      <b-tabs
        v-model="activeTab"
      >
        <!-- Approve Tab-->
        <b-tab
          title="Details"
          active
        >

          <b-alert
            :show="loading"
            variant="info"
          ><i class="fas fa-spinner fa-spin"/> Loading...</b-alert>

          <b-form
            v-if="!loading"
            ref="tab1Form"
          >
            <b-form-row class="mt-3">
              <b-form-group
                class="col-12 col-md-4"
                label="Internal Reference"
                label-for="ref"
                description="Internal Contract Reference"
              >
                <b-form-input
                  id="ref"
                  v-model="form.ref"
                  type="text"
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-4"
                label="Contract Reference"
                label-for="contractRef"
                description="Details of Any Framework Contract or Advertising Reference">
                <b-form-input
                  id="contractRef"
                  v-model="form.contract_ref"
                  name="contractRef"
                  type="text"
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-4"
                label="Status"
                label-for="status"
                description="Contract status">
                <b-form-input
                  id="statusDisplay"
                  v-model="form.status"
                />
              </b-form-group>

              <b-form-group
                class="col-12"
                label="Contract Title"
                label-for="contractTitle"
                description="Brief Description of Contract">
                <b-form-input
                  id="contractTitle"
                  v-model="form.name"
                  type="text"
                  required
                />
              </b-form-group>

              <b-form-group
                class="col-12"
                label="Contract Information"
                label-for="contractInformation"
                description="Contract Details">
                <b-form-textarea
                  id="contractInformation"
                  v-model="form.details"
                  :rows="6"
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-4"
                label="Contract Start Date"
                label-class="mt-md-4 mt-lg-auto"
                label-for="dateFrom"
              >
                <b-form-input
                  id="dateFrom"
                  v-model="form.from"
                  type="date"
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-4"
                label="Contract End Date"
                label-class="mt-md-4 mt-lg-auto"
                label-for="dateTo"
              >
                <b-form-input
                  id="dateTo"
                  v-model="form.to"
                  type="date"
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-4"
                label="Contract Length (months)"
                label-for="contractLength"
                description="Calculated between Start and End Date">
                <b-form-input
                  id="contractLength"
                  :value="contractLength"
                  class="text-right"
                  type="text"
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-3"
                label="Maximum Extension"
                label-for="noOfExtensions"
                description="Maximum number of extensions"
              >
                <b-form-input
                  id="noOfExtensions"
                  v-model="form.max_extensions"
                  type="text"
                  required
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-3"
                label="Extension Length"
                label-class="mt-md-4 mt-lg-auto"
                label-for="extensionPeriod"
                description="Length of an extension (months)"
              >
                <b-form-input
                  id="extensionPeriod"
                  v-model="form.extension_period"
                  type="text"
                  required
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-3"
                label="Current Extension"
                label-for="currentExtension"
                description="What extension number that the contract is currently on"
              >
                <b-form-input
                  id="currentExtension"
                  v-model="form.current_extension"
                  type="text"
                  required
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-3"
                label="Termination Date"
                label-class="mt-md-4 mt-lg-auto"
                label-for="finalDate"
                description="The date at which this contract must end"
              >
                <b-form-input
                  id="finalDate"
                  :value="finalDate"
                  type="text"
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-6"
                label="Return Document Date"
                label-for="returnDocDate">
                <b-form-input
                  id="returnDocDate"
                  v-model="form.document_return"
                  type="date"
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-6"
                label="Request Document Date"
                label-for="requestDocDate">
                <b-form-input
                  id="requestDocDate"
                  v-model="form.document_request"
                  type="date"
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-6"
                label="Indicative Value"
                label-for="contractValue"
              >
                <b-form-input
                  id="status"
                  v-model="form.indicative_value"
                  class="text-right"
                  type="text"
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-6"
                label="Value Duration"
                label-for="valueType"
              >
                <b-form-input
                  id="statusDisplay"
                  v-model="form.value_type"
                />
              </b-form-group>

              <b-form-group
                class="col-12"
                label="CPV Code"
                label-for="cVPCode"
                description="Common Procurement Vocabulary Code">
                <b-form-input
                  id="cVPCode"
                  v-model="form.cpv_code"
                  type="text"
                />
              </b-form-group>

              <b-form-group
                class="col-12"
                label="CPV Description"
                label-for="CVPDesc"
                description="Common Procurement Vocabulary Description">
                <b-form-input
                  id="cVPDesc"
                  v-model="form.cpv_description"
                  type="text"
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-6"
                label="Recurring"
                label-for="recurring"
                description="Single procurement or recurring">
                <b-form-input
                  id="recurring"
                  v-model="form.recurrence"
                  required
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-6"
                label="Procurement Process"
                label-for="procurementProcess"
                description="eg. Competetive Dialog, Open Tender">
                <b-form-input
                  id="procurementProcess"
                  v-model="form.procurement_process"
                />
              </b-form-group>

              <b-form-group
                class="col-12"
                label="Service"
                label-for="serviceArea"
                description="Service">
                <b-form-input
                  id="serviceArea"
                  v-model="form.service"
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-6"
                label="Contact Name"
                label-for="contactName"
                description="Charnwood Contact Name">
                <b-form-input
                  id="contactName"
                  v-model="form.contact_name"
                  type="text"
                  required
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-6"
                label="Contact Position"
                label-for="contactPosition"
                description="Charnwood Contact Position Held">
                <b-form-input
                  id="contactPosition"
                  v-model="form.contact_position"
                  type="text"
                  required
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-6"
                label="Contact Email"
                label-for="contactEmail"
                description="Charnwood Contact Email Address">
                <b-form-input
                  id="contactEmail"
                  v-model="form.contact_email"
                  type="email"
                  required
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-6"
                label="Contact Number"
                label-for="contactNumber"
                description="Charnwood Contact Telephone Number">
                <b-form-input
                  id="contactNumber"
                  v-model="form.contact_telephone"
                  type="text"
                  required
                />
              </b-form-group>

              <b-form-group
                class="col-12"
                label="Contact Postal Address"
                label-for="address"
                description="Charnwood Contact Postal Address">
                <b-form-textarea
                  id="address"
                  :value="contactAddress"
                  :rows="6"
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-6"
                label="Scope"
                label-for="scope"
                description="Scope of contract"
              >
                <b-form-input
                  id="scopeDisplay"
                  v-model="form.scope"
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-6"
                label="Scope Details"
                label-for="nameScope"
                description="Details of Scope"
              >
                <b-form-input
                  id="contactNumber"
                  v-model="form.scope_name"
                  type="text"
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-6"
                label-for="eTendering">
                <b-form-checkbox
                  id="eTendering"
                  v-model="form.etendering"
                  class="mt-4 pt-3"
                  value="1"
                  unchecked-value="0"
                >e-Tendering</b-form-checkbox>
              </b-form-group>
            </b-form-row>
          </b-form>
        </b-tab>

        <!-- Award Tab-->
        <b-tab
          title="Award"
        >
          <b-form
            v-if="!loading"
            ref="tab2Form"
          >
            <b-form-row class="mt-3">
              <b-form-group
                class="col-12 col-md-4"
                label="Date Awarded"
                label-for="dateAwarded"
                description="Date Constract Awarded to Supplier">
                <b-form-input
                  id="dateAwarded"
                  :value="awardedDate"
                  placeholder=" "
                  type="text"
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-8"
                label="Supplier Awarded to"
                label-for="supplierName"
                description="Contract Supplier"
              >
                <b-form-input
                  id="supplierName"
                  v-model="form.supplier_name"
                  type="text"
                />
              </b-form-group>

              <b-form-group
                class="col-12"
                label="Supplier Address"
                label-for="supplierAddress"
                description="Supplier Address"
              >
                <b-form-textarea
                  id="supplierAddress"
                  v-model="form.supplier_address"
                  :rows="6"
                />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-4 offset-md-8"
                label="Supplier Cost"
                label-for="supplierCost"
                description="Supplier Cost Value"
              >
                <b-form-input
                  id="supplierCost"
                  v-model="form.supplier_cost"
                  class="text-right"
                  type="text"
                />
              </b-form-group>
            </b-form-row>
          </b-form>
        </b-tab>
      </b-tabs>
      <div slot="modal-footer">
        <b-btn
          v-if="forTender"
          variant="success"
          @click="showInterest"
        >Register Interest</b-btn>
        <b-btn
          variant="primary"
          @click="hideModal">OK</b-btn>
      </div>

    </b-modal>

    <contractinterest
      ref="contractInterest"
      :form="form"
      @interestClosed="onHidden"
    />

    <notifications />

  </div>
</template>

<script>
import contractinterest from './ContractInterest.vue'

export default {
  components: { contractinterest },
  props: {
    contractId: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      id: 1,
      loading: true,
      validated: false,
      activeTab: 0,
      form: {},
      statusOptions: [
        { value: null, text: '-- Select an option --' },
        { value: 'Existing Contract', text: 'Existing Contract' },
        { value: 'New Contract', text: 'New Contract' },
        { value: 'Planned in Near Future', text: 'Planned in Near Future' }
      ],
      valueTypeOptions: [
        { value: null, text: '-- Select an option --' },
        { value: 'Per Annum', text: 'Per Annum' },
        { value: 'Length of Contract', text: 'Length of Contract' },
        { value: 'N/A', text: 'N/A' }
      ],
      recurringOptions: [
        { value: null, text: '-- Select an option --' },
        // { value: 'No (Single Procurement)', text: 'No (Single Procurement)' },
        // { value: 'Yes (Recurring)', text: 'Yes (Recurring)' }
        { value: 'One_off', text: 'No (Single Procurement)' },
        { value: 'Recurring', text: 'Yes (Recurring)'}
      ],
      procurementProcessOptions: [
        { value: null, text: '-- Select an option --' },
        { value: 'Competetive Dialog' , text: 'Competetive Dialog' },
        { value: 'Expressions of Interest' , text: 'Expressions of Interest' },
        { value: 'Framework Agreement' , text: 'Framework Agreement' },
        { value: 'One Off Interest' , text: 'One Off Interest' },
        { value: 'One Off Purchase' , text: 'One Off Purchase' },
        { value: 'One Off Supply' , text: 'One Off Supply' },
        { value: 'Open Tender' , text: 'Open Tender' },
        { value: 'Quotation' , text: 'Quotation' },
        { value: 'Restricted Tender' , text: 'Restricted Tender' }
      ],
      departmentOptions: [
        { value: null, text: '-- Select an option --' }
      ],
      sectionAreaOptions: [
        { value: null, text: '-- Select an option --' }
      ],
      serviceAreaOptions: [
        { value: null, text: '-- Select an option --' }
      ],
      scopeOptions: [
        { value: null, text: '-- Select an option --' },
        { value: 'CBC Only', text: 'CBC Only' },
        { value: 'Other Authorities', text: 'Other Auhtorities' }
      ],
      sMESupplierOptions: [
        { value: null, text: '-- Select an option --' },
        { value: 'Micro (10 or less employees)' , text: 'Micro (10 or less employees)' },
        { value: 'Small (11 to 50 employees)' , text: 'Small (11 to 50 employees)' },
        { value: 'Medium (51 to 250 employees)' , text: 'Medium (51 to 250 employees)' },
        { value: 'Large (250+ employees)' , text: 'Large (250+ employees)' }
      ],
      supplierMeetingsOptions: [
        { value: null, text: '-- Select an option --' },
        { value: 'Yearly' , text: 'Annual' },
        { value: 'Bi-Annual' , text: 'Bi-Annual' },
        { value: 'Quarterly' , text: 'Quarterly' },
        { value: 'Monthly' , text: 'Monthly' },
        { value: 'Weekly' , text: 'Weekly' }
      ],
      moment: window.moment
    }
  },
  computed: {
    contractLength: function () {
      return this.moment.duration(this.moment(this.form.to)
        .diff(this.moment(this.form.from)))
        .asMonths().toFixed(0)
    },
    finalDate: function () {
      return this.moment(this.form.to)
        .add(this.form.current_extension * this.form.extension_period
          , 'months')
        .format('DD/MM/YYYY')
    },
    awardedDate: function () {
      if(this.form.awarded_on)
        return this.moment(this.form.awarded_on).format('DD/MM/YYYY')
      return ''
    },
    contactAddress: function() {
      if (this.form.service)
        return this.form.service+'\nCharnwood Borough Council\nSouthfield Road\nLoughborough\nLE11 2TR'
      return ''
    },
    forTender () {
      return (this.form.awarded_on === null)
    }
  },
  watch: {
    // If the contractId "prop" changes loadData()
    contractId (ctx) {
      if (ctx === -1) {
        // Don't reset this.form as we may need it for register interest
        //this.form = {};
      } else {
        this.loadData()
      }
    }
  },
  methods: {
    contractIdChange () {

    },
    loadData() {
      this.loading = true
      this.activeTab = 0;
      this.$http.get('/finance/jsonContractDetailsById/'+this.contractId)
        .then(response => {
          this.form = response.data
          this.loading = false
        })
        .catch(() => {
          this.form = {}
          this.loading = false
        })
    },
    onHidden () {
    },
    hideModal () {
      this.$refs.detailsModal.hide()
    },
    showInterest () {
      // TODO: Is this the best way to reference this?
      this.$refs.contractInterest.$refs.interestModal.show()
    },
    modalHidden () {
      this.$emit('detailsClosed')
    }
  }
}
</script>
<style scoped>
.form-control{
  pointer-events: none;
}

#contractInformation {
  pointer-events: auto !important;
}
</style>
